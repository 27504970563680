
* {
  font-family: "Sono", sans-serif;
}
.App {
  text-align: center;
  background-color: #fff;
  background-image: url('./assets/images/coming-soon-bg.jpg');
  background-size: cover;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: #000;
}

.App-header h3 {
  font-size: 100px;
  font-weight: 300;
  margin: 20px 0;
  text-transform: uppercase;
  margin-bottom: 0;
}
.App-header p {
  font-size: 18px;
  color: grey;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .App-header h3 {
    font-size: 70px;
  }
}
